import { useEffect, useState } from "react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link } from "react-router-dom";


const Header: React.FC = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false); // Manage sticky behavior

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // Function to control header visibility based on scroll
    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > 0) {
            // If scrolled down, make the header sticky
            setIsSticky(true);
        } else {
            // If at the top, reset header to normal position
            setIsSticky(false);
        }
    };

    useEffect(() => {
        // Add scroll event listener on mount
        window.addEventListener("scroll", handleScroll);
        return () => {
            // Clean up event listener on unmount
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <header className={`transition-all duration-300 ease-in-out ${isSticky ? 'fixed top-0 left-0 right-0 bg-white shadow-lg z-50' : 'relative'} `}>
                <div className="flex items-center justify-between h-16 lg:ml-36 lg:mr-36 mt-3 mb-3 mx-6">
                    <img
                        src="/logo.png"
                        width={60}
                        height={60}
                        className="rounded-full object-cover"
                        alt="News Logo"
                    />
                    <div className="hidden md:flex items-center">
                        <a href="#section3" className="gradient-text mr-10 pb-1 font-bold group">
                            How It Works
                        </a>
                        <a href="#section2" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            Features
                        </a>
                        <a href="/" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            Blog
                        </a>
                        {/* <a href="/contact" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            Contact
                        </a> */}
                        <Link to="/contact" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            Contact Us
                        </Link>
                        <Link to="/about" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            About Us
                        </Link>
                        {/* <a href="/about" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            About Us
                        </a> */}
                    </div>
                    <div></div>
                    <div className="md:hidden flex items-center">
                        <button onClick={toggleMobileMenu} className="text-black">
                            {isMobileMenuOpen ? (
                                <IoMdClose size={30} />
                            ) : (
                                <IoMdMenu size={30} />
                            )}
                        </button>
                    </div>
                </div>

                {isMobileMenuOpen && (
                    <div className='md:hidden flex flex-col items-center p-4 transition-all duration-500 ease-in-out'>
                        <a href="#section3" className="pb-4">
                            <label className="gradient-text font-bold hover:border-[#8E4042]">
                                How It Works
                            </label>
                        </a>
                        <a href="#section2" className="pb-4">
                            <label className="gradient-text font-bold hover:border-[#8E4042]">
                                Features
                            </label>
                        </a>
                        <a href="/" className="pb-4">
                            <label className="gradient-text font-bold hover:border-[#8E4042]">
                                Blog
                            </label>
                        </a>
                        {/* <a href="/contact" className="pb-4">
                            <label className="gradient-text font-bold hover:border-[#8E4042]">
                                Contact
                            </label>
                        </a>
                        <a href="/about" className="pb-4">
                            <label className="gradient-text font-bold hover:border-[#8E4042]">
                                About Us
                            </label>
                        </a> */}

                        <Link to="/contact" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            Contact Us
                        </Link>
                        <Link to="/about" className="gradient-text mr-10 pb-1 font-bold hover:bg-black text-white">
                            About Us
                        </Link>
                    </div>
                )}
            </header>
        </>
    )
}

export default Header;