import { FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="bg-gray-100">
                {/* Logo and Social Icons */}
                <div className="h-16 lg:ml-36 lg:mr-36 mt-3 mb-3 mx-6">
                    <div className="flex  md:flex-row items-center border-t border-gray-300 w-full justify-between pb-1">
                        <img
                            src="/logo.png"
                            width={60}
                            height={60}
                            className="rounded-full object-cover mt-5 mb-4 md:mb-0"
                            alt="News Logo"
                        />
                        <div className="flex justify-center">
                            <div className="flex items-center space-x-4 md:space-x-10">
                                <a href="/" className="font-bold">
                                    <FaInstagram size={30} color="#930700e5" />
                                </a>
                                <a href="/" className="font-bold">
                                    <FaTwitter size={30} color="#930700e5" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Middle Section - Services, About, Help */}
                <div className="py-4 px-6">
                    <div className="max-w-7xl mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-gray-700 text-center md:text-left">
                            {/* Services Section */}
                            <div>
                                <p className="text-lg font-bold text-[#930700e5] mb-4">Services</p>
                                <ul className="space-y-2">
                                    <li>Email Marketing</li>
                                    <li>Campaigns</li>
                                    <li>Branding</li>
                                    <li>Offline</li>
                                </ul>
                            </div>

                            {/* About Section */}
                            <div>
                                <p className="text-lg font-bold text-[#930700e5] mb-4">About</p>
                                <ul className="space-y-2">
                                    <li>Our Story</li>
                                    <li>Benefits</li>
                                    <li>Terms & Conditions</li>
                                    <li>Privacy Policy</li>
                                </ul>
                            </div>

                            {/* Help Section */}
                            <div>
                                <p className="text-lg font-bold text-[#930700e5] mb-4">Help</p>
                                <ul className="space-y-2">
                                    <li>FAQ</li>
                                    {/* <li>Contact Us</li> */}
                                    <Link to="/contact">Contact Us</Link>
                                    <li>Team</li>
                                    <li>Careers</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Links and Rights */}
                <div className="flex flex-col md:flex-row items-center justify-between lg:ml-36 lg:mr-36 mt-7 pb-2 mx-6">
                    <div className="text-center md:text-left">
                        <p className="mr-5">All Rights Reserved</p>
                    </div>
                    <div className="text-center md:text-right mt-3 md:mt-0">
                        <a href="#" className="text-[#930700e5]">
                            easyturf.com
                        </a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;