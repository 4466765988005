import { useEffect, useRef, useState } from "react";
import { addContactUsDetail } from "../services/api";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Contact: React.FC = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone: '',
        description: ''
    });
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 } // Trigger when 20% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    // Scroll to top after navigation
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!formValues.name.trim()) {
            newErrors.name = "Name is required";
        }
        if (!formValues.email.trim()) {
            newErrors.email = "Email is required";
        }
        if (!formValues.phone.trim()) {
            newErrors.phone = "Phone is required";
        }
        if (!formValues.description.trim()) {
            newErrors.description = "Description is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!validateForm()) {
            // If validation fails, do not proceed
            return;
        }
        const formData = new FormData();
        formData.append('name', formValues.name);
        formData.append('email', formValues.email);
        formData.append('phone', formValues.phone);
        formData.append('description', formValues.description);
        try {
            let body = {
                name: formValues.name,
                email: formValues.email,
                phone: formValues.phone,
                description: formValues.description
            }
            console.log(body, "body 1 edit");
            let result = await addContactUsDetail(body); // Assume you have `updatePartner` function similar to `addPartner`
            console.log(result.data.data);
            if (result.data.status) {
                toast.success(result.data.message);
                setTimeout(() => {
                    navigate('/');
                    window.scrollTo(0, 0);
                }, 5000);
            } else {
                toast.error("Somthing went wrong...");
            }
        } catch (err) {
            console.log(err, "err");
        }
    }

    return (
        <>
            <div className="w-full">
                <img src="contact-us.png" className="w-full object-cover opacity-90 h-80 lg:h-96 md:h-96" alt="" />

                <div ref={sectionRef} className={`mx-4 md:mx-16 lg:mx-96 ${isVisible ? 'fade-in-up' : ''}`}>
                    <p className="text-2xl md:text-3xl font-bold text-center mt-8 gradient-text">Contact Us</p>
                    <p className="px-4 md:px-32 pt-10 text-center text-lg md:text-xl">Our team are happy to answer any questions you may have about how we can help you. If you are interested in using our services we offer a free initial consultation which will be arranged with one of our directions. If you fill in the form below one of our team will be in touch shortly.</p>

                    <div className={`card text-black flex flex-col md:flex-row items-center justify-center border w-full mt-10 mb-10 shadow-xl bg-white `}>
                        <div className="card-body p-4">
                            <div className="flex flex-col md:flex-row items-center justify-between w-full space-y-4 md:space-y-0">
                                <div className="w-full md:w-1/3 lg:w-1/4">
                                    <p className="text-sm text-black font-bold">Full Name:</p>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Enter Full Name"
                                        className="input input-bordered w-full"
                                        value={formValues.name}
                                        onChange={handleInputChange}
                                    />
                                    {errors.name && <p className="text-red-500 text-xs mb-3">{errors.name}</p>}
                                </div>
                                <div className="w-full md:w-1/3 lg:w-1/4">
                                    <p className="text-sm text-black font-bold">Email:</p>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        className="input input-bordered w-full"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <p className="text-red-500 text-xs mb-3">{errors.email}</p>}
                                </div>
                                <div className="w-full md:w-1/3 lg:w-1/4">
                                    <p className="text-sm text-black font-bold">Phone Number:</p>
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder="Enter Phone number"
                                        className="input input-bordered w-full"
                                        value={formValues.phone}
                                        onChange={handleInputChange}
                                    />
                                    {errors.phone && <p className="text-red-500 text-xs mb-3">{errors.phone}</p>}
                                </div>
                            </div>
                            <div className="mt-4">
                                <p className="text-sm text-black font-bold">Message:</p>
                                <textarea
                                    name="description"
                                    value={formValues.description}
                                    placeholder="Enter Message"
                                    onChange={handleInputChange}
                                    className="textarea textarea-bordered w-full"></textarea>
                                {errors.description && <p className="text-red-500 text-xs mb-3">{errors.description}</p>}
                            </div>
                            <div className="flex items-center justify-end mt-4">
                                <button onClick={handleSubmit} className="btn text-white hover:bg-gray-800" style={{
                                    background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)'
                                }}>Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
            </div>
        </>
    )
}

export default Contact;