import { useEffect, useRef, useState } from "react";

const About: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 } // Trigger when 20% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <>
            <div className="w-full">
                <img src="About-us-min.jpg" className="w-full object-cover h-80 lg:h-96 md:h-96" alt="" />

                <div ref={sectionRef} className={`flex flex-col items-center justify-between mx-4 md:mx-16 lg:mx-96 mt-10 mb-10 ${isVisible ? 'fade-in-up' : ''}`}>
                    <div className="container mx-auto mb-4">
                        <div className="flex flex-col lg:flex-row justify-between items-center mb-7">
                            <div className={`relative lg:w-1/2 flex justify-center lg:justify-start`}>
                                <img src="mission.jpg" alt="Phone Mockup 1" className="relative w-3/4 lg:w-full rounded-xl" />
                            </div>
                            <div className={`lg:w-1/2 text-center ml-7 lg:text-left `}>
                                <p className="text-4xl lg:text-3xl font-bold leading-7 mb-6 ">
                                    Our Mission: Helping Millions of organizations Grow Better<br />
                                </p>
                                <p className="text-gray-600 p-4 lg:p-0 mb-6">
                                    We believe not just in growing bigger, but in growing better, And growing better means aligning the success of your own business with the success of your customers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container mx-auto mt-4">
                        <div className="flex flex-col lg:flex-row justify-between items-center">
                            <div className={`lg:w-1/2 text-center ml-7 lg:text-left `}>
                                <p className="text-4xl lg:text-3xl font-bold leading-7 mb-6 ">
                                    Our Vision: Helping Millions of organizations Grow Better<br />
                                </p>
                                <p className="text-gray-600 p-4 lg:p-0 mb-6">
                                    We believe not just in growing bigger, but in growing better, And growing better means aligning the success of your own business with the success of your customers.
                                </p>
                            </div>
                            <div className={`relative lg:w-1/2 flex justify-center lg:justify-end`}>
                                <img src="vision.jpg" alt="Phone Mockup 1" className="relative w-3/4 lg:w-full rounded-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;