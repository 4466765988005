import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';

function App() {
  return (
    <div>
      {/* Header is common across all pages */}
      <Header />

      {/* Define the Routes and ensure route components are rendered via Outlet */}
      <Routes>
        {/* Replace 'Component' with 'element' */}
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
      </Routes>

      {/* Footer is also common across all pages */}
      <Footer />
    </div>
  );
}

export default App;
