import { useEffect, useRef, useState } from "react";

const Section3 = ({ id }: any) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <>
            <section id={id} ref={sectionRef} className="mt-16">
                <div className="container mx-auto mb-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className={`relative lg:w-1/2 flex justify-center lg:justify-start ${isVisible ? 'fade-in-up' : ''}`}>
                            <img src="m3.png" alt="Phone Mockup 1" className="relative w-3/4 lg:w-1/2" />
                        </div>
                        <div className={`lg:w-1/2 text-center lg:text-left ${isVisible ? 'fade-in-up' : ''}`}>
                            <p className="text-4xl lg:text-5xl font-bold leading-7 mb-6 ">
                                Book Your <br />
                                <span className="relative inline-block">
                                    <span className="absolute inset-x-0 bottom-0 h-2 ml-2 bg-yellow-300"></span>
                                    <span className="relative text-[#930700e5] ml-2"> Slots</span>
                                </span> <br />
                                On Your Finger Tips
                            </p>
                            <p className="text-gray-600 p-4 lg:p-0 mb-6">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur facere quas blanditiis! Libero quibusdam quae blanditiis impedit. Iste doloremque, aliquam optio, excepturi laborum, rem dolorem ullam in praesentium molestias enim!
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="container mx-auto mt-4 mb-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className={`lg:w-1/2 text-center lg:text-left ${isVisible ? 'fade-in-up' : ''}`}>
                            <p className="text-4xl lg:text-5xl font-bold leading-7 mb-6 ">
                                Quick and Easy <br />
                                <span className="relative inline-block">
                                    <span className="absolute inset-x-0 bottom-0 h-2 ml-2 bg-yellow-300"></span>
                                    <span className="relative text-[#930700e5] ml-2"> Payments</span>
                                </span> With <br />
                                Just a Few Clicks
                            </p>
                            <p className="text-gray-600 p-4 lg:p-0 mb-6">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur facere quas blanditiis! Libero quibusdam quae blanditiis impedit. Iste doloremque, aliquam optio, excepturi laborum, rem dolorem ullam in praesentium molestias enim!
                            </p>
                        </div>
                        <div className={`relative lg:w-1/2 flex justify-center lg:justify-end ${isVisible ? 'fade-in-up' : ''}`}>
                            <img src="m2.png" alt="Phone Mockup 1" className="relative w-3/4 lg:w-1/2" />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="container mx-auto mt-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className={`relative lg:w-1/2 flex justify-center lg:justify-start ${isVisible ? 'fade-in-up' : ''}`}>
                            <img src="s6.jpg" alt="Phone Mockup 1" className="relative w-3/4 lg:w-1/2" />
                        </div>
                        <div className={`lg:w-1/2 text-center lg:text-left ${isVisible ? 'fade-in-up' : ''}`}>
                            <p className="text-4xl lg:text-5xl font-bold leading-7 mb-6 ">
                                You Can View <br />
                                <span className="relative inline-block">
                                    <span className="absolute inset-x-0 bottom-0 h-2 ml-2 bg-yellow-300"></span>
                                    <span className="relative text-[#930700e5] ml-2"> Transcation</span>
                                </span> <br />
                                History Easily
                            </p>
                            <p className="text-gray-600 p-4 lg:p-0 mb-6">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur facere quas blanditiis! Libero quibusdam quae blanditiis impedit. Iste doloremque, aliquam optio, excepturi laborum, rem dolorem ullam in praesentium molestias enim!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section3;