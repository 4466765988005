import { useEffect, useRef, useState } from "react";

const Section1 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const appStore = () => {
        window.open('https://apps.apple.com', '_blank');
    }

    const playStore = () => {
        window.open('https://play.google.com/store', '_blank');
    }

    return (
        <>
            <section ref={sectionRef} className="">
                <div className="container mx-auto ">
                    <div className="flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0">
                        <div className={`lg:w-1/2 text-center lg:text-left ${isVisible ? 'fade-in-up' : ''}`}>
                            <p className="text-4xl lg:text-5xl font-bold leading-7 mb-6 ">
                                Manage Your <br />
                                Business
                                <span className="relative inline-block">
                                    <span className="absolute inset-x-0 bottom-0 h-2 ml-2 bg-yellow-300"></span>
                                    <span className="relative text-[#930700e5] ml-2"> Finance</span>
                                </span> <br />
                                Easily
                            </p>
                            <p className="text-gray-600 p-4 lg:p-0 mb-6">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur facere quas blanditiis! Libero quibusdam quae blanditiis impedit. Iste doloremque, aliquam optio, excepturi laborum, rem dolorem ullam in praesentium molestias enim!
                            </p>
                            <button onClick={appStore} style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }} className="text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-green-700 transition duration-300 ml-4">
                                <div className="flex flex-row items-center">
                                    <img src="app-store.png" className="h-5 w-5 mr-2" alt="" /> <p>App Store</p>
                                </div>
                            </button>
                            <button onClick={playStore} style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }} className="text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-green-700 transition duration-300 ml-4">
                                <div className="flex flex-row items-center">
                                    <img src="play-store.png" className="h-5 w-5 mr-2" alt="" /> <p>Play Store</p>
                                </div>
                            </button>
                        </div>
                        <div className={`relative lg:w-1/2 flex justify-center lg:justify-end ${isVisible ? 'fade-in-up' : ''}`}>
                            {/* <!-- Main Mockup Image (s1.png) --> */}
                            <img src="main.png" alt="Phone Mockup 1" className="relative w-3/4 lg:w-1/2" />
                            {/* <!-- Overlapping Mockup Image (s2.png) Top Left --> */}
                            {/* <img src="s2.png" alt="Phone Mockup 2" className="absolute top-0 right-40 w-1/3 lg:w-1/6 transform translate-y-8 -translate-x-8 lg:-translate-y-10 lg:-translate-x-12" /> */}

                            {/* <!-- Overlapping Mockup Image (s3.png) Bottom Right --> */}
                            {/* <img src="s3.png" alt="Phone Mockup 3" className="absolute bottom-0 right-0 w-1/4 lg:w-1/6 transform translate-y-10 translate-x-10" /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section1;