import { FaHistory, FaMapMarkerAlt, FaRegCalendarCheck, FaRupeeSign } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { useEffect, useRef, useState } from "react";

const Section2 = ({ id }: any) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef<any>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false); // Optional: Hide when not visible
                }
            },
            { threshold: 0.1 } // Adjusted threshold
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <>
            {/* <section ref={sectionRef} className={`transition-opacity duration-1000 ${isVisible ? 'animate__visible' : 'animate__animated'}`}> */}
            <section id={id} ref={sectionRef} className={`transition-opacity duration-1000 ${isVisible ? 'animate__visible' : 'animate__animated'}`}
                style={{ minHeight: '0vh', backgroundColor: 'white' }}>
                <div className="container mx-auto mt-20">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-4xl text-center lg:text-5xl font-bold leading-7 mb-6 ">
                            Some Excellent
                            <span className="relative inline-block">
                                <span className="absolute inset-x-0 bottom-0 h-2 ml-2 bg-yellow-300"></span>
                                <span className="relative text-[#930700e5] ml-2 mr-2"> Features </span>
                            </span>
                            For You
                        </p>
                        <p className="text-gray-600 text-center text-sm p-4 lg:p-0 mb-6 w-1/2">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur facere quas blanditiis! Libero quibusdam quae blanditiis impedit. Iste doloremque, aliquam optio, excepturi laborum, rem dolorem ullam in praesentium molestias enim!
                        </p>
                    </div>
                    <Swiper
                        spaceBetween={10} // Adjust space between slides
                        slidesPerView={4} // Show one slide at a time
                        autoplay={{ delay: 3000 }} // Autoplay every 3 seconds
                        pagination={{ clickable: true }} // Optional: add pagination
                        loop={true} // Loop the slides
                        modules={[Autoplay, Pagination]} // Import and use the Autoplay module
                        className="mySwiper" // Optional: add a class for custom styling
                        breakpoints={{
                            410: {
                                slidesPerView: 1
                            },
                            640: {
                                slidesPerView: 1, // 1 slide for screens >= 640px
                            },
                            768: {
                                slidesPerView: 2, // 2 slides for screens >= 768px
                            },
                            1024: {
                                slidesPerView: 4, // 4 slides for screens >= 1024px
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaRupeeSign size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">Bill Payment</p>
                                    <p className="text-sm text-center mb-5">Make payments easily using UPI/Wallet.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaRegCalendarCheck size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">Book Slot</p>
                                    <p className="text-sm text-center mb-5">Book your slot via App.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaMapMarkerAlt size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">NearBy turf</p>
                                    <p className="text-sm text-center mb-5">Easily find near by turf ground via App.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaHistory size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">Transaction History</p>
                                    <p className="text-sm text-center mb-5">See your transaction details.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaRupeeSign size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">Bill Payment</p>
                                    <p className="text-sm text-center mb-5">Make payments easily using UPI/Wallet.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaRegCalendarCheck size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">Book Slot</p>
                                    <p className="text-sm text-center mb-5">Book your slot via App.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaMapMarkerAlt size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">NearBy turf</p>
                                    <p className="text-sm text-center mb-5">Easily find near by turf ground via App.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card card-compact bg-base-100 w-full lg:w-72 shadow-xl">
                                <figure className="h-[100px]" style={{ background: 'linear-gradient(118deg, rgba(0,0,0,1) 0%, rgba(147,7,0,1) 100%)' }}>
                                    <FaHistory size={40} color="white" />
                                </figure>
                                <div className="card-body">
                                    <p className="card-title text-2xl font-bold flex items-center justify-center">Transaction History</p>
                                    <p className="text-sm text-center mb-5">See your transaction details via APP.</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Section2;