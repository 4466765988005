import { useEffect, useRef, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Section5 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const testimonials = [
        {
            name: 'Mila McSabbu',
            role: 'Designer',
            avatar: 'avtar1.png',
            testimonial: 'Leading an organization is incredibly rewarding and equally humbling. Confidence and humility...',
        },
        {
            name: 'John Doe',
            role: 'Developer',
            avatar: 'avtar3.jpg',
            testimonial: 'Success is built on lessons from mistakes made. It requires humility and confidence...',
        },
        {
            name: 'John Doe',
            role: 'Developer',
            avatar: 'avtar2.png',
            testimonial: 'Leading an organization is incredibly rewarding and equally humbling. Confidence and humility...',
        },
        {
            name: 'John Doe',
            role: 'Developer',
            avatar: 'avtar4.jpg',
            testimonial: 'Success is built on lessons from mistakes made. It requires humility and confidence...',
        },
    ];

    return (
        <>
            <section ref={sectionRef}>
                <div className="container mx-auto mb-4">
                    <div className={`transition-opacity duration-1000 ${isVisible ? 'animate__visible' : 'animate__animated'}`}
                        style={{ minHeight: '0vh', backgroundColor: 'white' }}>
                        <div className="text-center p-8">
                            <p className="text-4xl text-center lg:text-5xl font-bold leading-7 mb-6 ">
                                What Our Happy <br />
                                <span className="relative inline-block">
                                    <span className="absolute inset-x-0 bottom-0 h-2 ml-2 bg-yellow-300"></span>
                                    <span className="relative text-[#930700e5] ml-2 mr-2"> Users </span>
                                </span>
                                Say
                            </p>
                            <p className="text-gray-500 mb-8">Read real experience of our user</p>

                            <Swiper
                                spaceBetween={50}
                                slidesPerView={3}
                                autoplay={{ delay: 3000 }} // Autoplay every 3 seconds
                                loop={true} // Loop the slides
                                modules={[Autoplay]} // Import and use the Autoplay and Pagination modules
                                className="mySwiper" // Optional: add a class for custom styling
                                breakpoints={{
                                    410: {
                                        slidesPerView: 1
                                    },
                                    640: {
                                        slidesPerView: 1, // 1 slide for screens >= 640px
                                    },
                                    768: {
                                        slidesPerView: 2, // 2 slides for screens >= 768px
                                    },
                                    1024: {
                                        slidesPerView: 3, // 3 slides for screens >= 1024px
                                    },
                                }}
                            >
                                {testimonials.map((testimonial: any, index: any) => (
                                    <SwiperSlide key={index}>
                                        <div className="relative flex justify-center items-center space-x-4 mb-8">
                                            <div className="w-24 h-24 bg-gray-300 rounded-full swiper-slide-active:w-32 swiper-slide-active:h-32 transition-all duration-300 ease-in-out">
                                                <img src={testimonial.avatar} alt={testimonial.name} className="rounded-full w-full h-full" />
                                            </div>
                                        </div>

                                        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[100px] w-px bg-gray-300"></div>

                                        <div className="text-lg text-gray-700">
                                            <p>{testimonial.testimonial}</p>
                                            <h4 className="font-semibold mt-4">{testimonial.name}</h4>
                                            <p className="text-sm text-gray-500 mb-10">{testimonial.role}</p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section5;