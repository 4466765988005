import { useEffect, useRef, useState } from "react";

const Section4 = ({ id }: any) => {
    const [isVisible, setIsVisible] = useState(false);
    const [activeUsers, setActiveUsers] = useState(0);
    const [userPassive, setUserPassive] = useState(0);
    const [increaseRate, setIncreaseRate] = useState(0);
    const [testimonials, setTestimonials] = useState(0);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    // Function to animate numbers
    const animateNumbers = (start: any, end: any, duration: any, setValue: any) => {
        let startTime: any;

        const step = (timestamp: any) => {
            if (!startTime) startTime = timestamp;
            const progress = Math.min((timestamp - startTime) / duration, 1);
            setValue(Math.floor(progress * (end - start) + start));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    };

    useEffect(() => {
        if (isVisible) {
            animateNumbers(0, 115000, 2000, setActiveUsers);
            animateNumbers(0, 88000, 2000, setUserPassive);
            animateNumbers(0, 30, 2000, setIncreaseRate);
            animateNumbers(0, 10000, 2000, setTestimonials);
        }
    }, [isVisible]);

    return (
        <>
            <section id={id} ref={sectionRef} className="py-10">
                <div className={`container mx-auto mb-4 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-10 py-10">
                        <div className="text-center">
                            <h3 className="text-3xl md:text-4xl font-bold">{activeUsers}k+</h3>
                            <p className="text-sm md:text-base text-gray-500">Active user</p>
                        </div>

                        <div className="h-px w-full md:h-16 md:w-px bg-gray-300"></div>

                        <div className="text-center">
                            <h3 className="text-3xl md:text-4xl font-bold">{userPassive}k</h3>
                            <p className="text-sm md:text-base text-gray-500">Bookings</p>
                        </div>

                        <div className="h-px w-full md:h-16 md:w-px bg-gray-300"></div>

                        <div className="text-center">
                            <h3 className="text-3xl md:text-4xl font-bold">{increaseRate}</h3>
                            <p className="text-sm md:text-base text-gray-500">Cricket Boxes</p>
                        </div>

                        <div className="h-px w-full md:h-16 md:w-px bg-gray-300"></div>

                        <div className="text-center">
                            <h3 className="text-3xl md:text-4xl font-bold">&gt; {testimonials}k</h3>
                            <p className="text-sm md:text-base text-gray-500">Good Testimonials</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section4;